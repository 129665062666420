<script setup>
import Articles from '@/components/Articles.vue'
import TagArea from '@/components/TagArea.vue'

const title = 'Blog | Lyber'
const description = 'Un numero per la tua attività, con assistente virtuale per ricevere ovunque le chiamate dei tuoi clienti e gestirle con facilità.'
useSeoMeta({
  title,
  ogTitle: title,
  description: description,
 ogDescription: description
})
</script>

<template>
  <div class="px-vw6">
    <TagArea class="hidden lg:block" />
    <Articles />
  </div>
</template>